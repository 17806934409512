import { FC } from "react";
import "./App.css";

const App: FC = () => {
  return (
    <div className="App">
      <p>Web site is under construction.</p>
    </div>
  );
};

export default App;
